import ApiBase from './ApiBase';

export default class WhitelabelService extends ApiBase {
  async find(hostname = window.location.hostname, storeId) {
    if (!hostname && !storeId) {
      return {};
    }

    const { data } = await this.client.get('whitelabel/settings', {
      params: {
        domain: hostname,
        store: storeId,
      },
    });

    return data;
  }
}
