import { shallowMount } from '@vue/test-utils';
import { vi } from 'vitest';

import { ORDER_ITEM } from '~/support/fixtures';

import Component from './ItemIssueDetailModalSelectCount.vue';

let wrapper;
let vm;

describe('ItemIssueDetailModalSelectCount', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: {
        item: ORDER_ITEM,
      },
    });

    vm = wrapper.vm;
  });

  describe('methods', () => {
    describe('handleContinueClick', () => {
      test('invalid', () => {
        vm.$v.$touch = vi.fn();

        vm.handleContinueClick();
        expect(vm.$v.$touch).toHaveBeenCalled();
        expect(vm.$v.$invalid).toBeTruthy();
      });

      test('valid', async () => {
        await wrapper.find('[data-hubble="1-items-with-issues"]').trigger('click');

        await vm.handleContinueClick();
        expect(wrapper.emitted('continue')).toHaveLength(1);
      });
    });
  });
});
