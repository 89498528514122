import { shallowMount } from '@vue/test-utils';
import { vi } from 'vitest';

import { ORDER_ITEM } from '~/support/fixtures';

import Component from './ItemIssueDetailModalSelectGranularity.vue';

let wrapper;
let vm;

describe('ItemIssueDetailModalSelectGranularity', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, { props: { item: ORDER_ITEM } });
    vm = wrapper.vm;
  });

  describe('methods', () => {
    describe('handleContinueClick', () => {
      test('invalid', () => {
        vm.$v.$touch = vi.fn();

        vm.handleContinueClick();
        expect(vm.$v.$touch).toHaveBeenCalled();
        expect(vm.$v.$invalid).toBeTruthy();
      });

      test('valid', async () => {
        await wrapper.find('[data-hubble="all-items-have-same-issue-true"]').trigger('click');

        await vm.handleContinueClick();
        expect(wrapper.emitted('continue')).toHaveLength(1);

        await wrapper.find('[data-hubble="all-items-have-same-issue-false"]').trigger('click');

        await vm.handleContinueClick();
        expect(wrapper.emitted('continue')).toHaveLength(2);
      });
    });
  });
});
