import ApiBase from './ApiBase';

export default class ClaimService extends ApiBase {
  async findClaimSession(token) {
    const { data } = await this.client.get(`sessions/${token}`);

    return data;
  }

  async findClaimById(id) {
    const { data } = await this.client.get(`claims/${id}`);

    return data;
  }

  async createClaimMessage(data, storeId, sourceOrderId) {
    return this.client.post(`claims/${sourceOrderId}/messages?storeId=${storeId}`, data);
  }

  async loadClaimMessages(claimId, sourceOrderId) {
    const { data } = await this.client.get(`claims/${sourceOrderId}/messages?claimId=${claimId}`);

    return data;
  }

  transformStoreToClaim(email, claimInfo) {
    return {
      email,
      fulfillmentIssues: Object.entries(claimInfo.fulfillments).reduce((acc, [sourceFulfillmentId, fulfillment]) => {
        acc.push({
          issues: fulfillment.issues,
          sourceFulfillmentId,
        });

        return acc;
      }, []),
      resolution: {
        customerSignature: claimInfo.signature,
        desiredMethod: claimInfo.resolution.desiredMethod,
        updatedShippingAddress: claimInfo.resolution.updatedShippingAddress,
      },
    };
  }
  async createClaim({ email, claim, sourceOrderId }) {
    const transformed = this.transformStoreToClaim(email, claim);

    return this.client.post(`claims/${sourceOrderId}`, transformed);
  }
}
