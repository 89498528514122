import { request } from '~/support/utils';

export default class ApiBase {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get client() {
    return request.create({
      baseURL: this.baseUrl,
    });
  }
}
