import { shallowMount } from '@vue/test-utils';
import { createPinia, setActivePinia, storeToRefs } from 'pinia';

import { useOrdersStore } from '~/store';
import { ORDER_ITEM, ORDER_WITH_ITEMS } from '~/support/fixtures';

import Component from './StepSubtitle.vue';

const items = [ORDER_ITEM];

let wrapper;

describe('StepSubtitle', () => {
  beforeEach(() => {
    setActivePinia(createPinia());

    const store = useOrdersStore();
    const { currentOrder } = storeToRefs(store);

    currentOrder.value = ORDER_WITH_ITEMS;

    wrapper = shallowMount(Component, {
      props: {
        items,
      },
    });
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
