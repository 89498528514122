import { shallowMount } from '@vue/test-utils';

import { ORDER_ITEM, ORDER_WITH_ITEMS } from '~/support/fixtures';

import Component from './ItemsModal.vue';

const items = [ORDER_ITEM];
const order = ORDER_WITH_ITEMS;

let wrapper;

describe('ItemsModal', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: {
        items,
        order,
      },
    });
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
