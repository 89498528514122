import { default as claim_45received_46specnV1O0VErSZMeta } from "/app/apps/concierge/pages/claim-received.spec.js?macro=true";
import { default as claim_45received0n0kc4Vlt3Meta } from "/app/apps/concierge/pages/claim-received.vue?macro=true";
import { default as index_46specSG2xsqSkKrMeta } from "/app/apps/concierge/pages/index.spec.js?macro=true";
import { default as indexaEyyma7KiUMeta } from "/app/apps/concierge/pages/index.vue?macro=true";
import { default as issues_46spec9DI3wSxTrZMeta } from "/app/apps/concierge/pages/issues.spec.js?macro=true";
import { default as index_46spec7ExMYrkjSdMeta } from "/app/apps/concierge/pages/issues/index.spec.js?macro=true";
import { default as indexXHhrx8AMAoMeta } from "/app/apps/concierge/pages/issues/index.vue?macro=true";
import { default as items_46specK13pdLlQTJMeta } from "/app/apps/concierge/pages/issues/items.spec.js?macro=true";
import { default as itemsoTp4jmYnmrMeta } from "/app/apps/concierge/pages/issues/items.vue?macro=true";
import { default as shipment_46specMM3csjOPNtMeta } from "/app/apps/concierge/pages/issues/shipment.spec.js?macro=true";
import { default as details_46spec0tRvqU2qtPMeta } from "/app/apps/concierge/pages/issues/shipment/details.spec.js?macro=true";
import { default as detailsPvmaSAOkQRMeta } from "/app/apps/concierge/pages/issues/shipment/details.vue?macro=true";
import { default as index_46speceT76NfvcHqMeta } from "/app/apps/concierge/pages/issues/shipment/index.spec.js?macro=true";
import { default as indexATsgyv5dpyMeta } from "/app/apps/concierge/pages/issues/shipment/index.vue?macro=true";
import { default as shipmentl8iy5MyHI5Meta } from "/app/apps/concierge/pages/issues/shipment.vue?macro=true";
import { default as issuesHKnBN9s0EZMeta } from "/app/apps/concierge/pages/issues.vue?macro=true";
import { default as index_46specpl6OPhbqUpMeta } from "/app/apps/concierge/pages/multiple-shipments/index.spec.js?macro=true";
import { default as indexHk0TjdE1QVMeta } from "/app/apps/concierge/pages/multiple-shipments/index.vue?macro=true";
import { default as not_45shipped_46specCgHZMMU91kMeta } from "/app/apps/concierge/pages/not-shipped.spec.js?macro=true";
import { default as not_45shippedk8q6zumAKOMeta } from "/app/apps/concierge/pages/not-shipped.vue?macro=true";
import { default as _91token_93XYtyJqCNbXMeta } from "/app/apps/concierge/pages/print/[token].vue?macro=true";
import { default as resolution_46spec14eNd8q9ZcMeta } from "/app/apps/concierge/pages/resolution.spec.js?macro=true";
import { default as index_46speclfWHZVaI8YMeta } from "/app/apps/concierge/pages/resolution/index.spec.js?macro=true";
import { default as indexZd2hfUknkhMeta } from "/app/apps/concierge/pages/resolution/index.vue?macro=true";
import { default as review_46specoFFwD9zfzaMeta } from "/app/apps/concierge/pages/resolution/review.spec.js?macro=true";
import { default as reviewB4TiWrpLhEMeta } from "/app/apps/concierge/pages/resolution/review.vue?macro=true";
import { default as resolutionz9WI0ynKA4Meta } from "/app/apps/concierge/pages/resolution.vue?macro=true";
import { default as start_46specf1YSonSL2mMeta } from "/app/apps/concierge/pages/start.spec.js?macro=true";
import { default as start4Y0HwDBwxUMeta } from "/app/apps/concierge/pages/start.vue?macro=true";
import { default as too_45soon_46specMAoWCBe5hdMeta } from "/app/apps/concierge/pages/too-soon.spec.js?macro=true";
import { default as too_45soonly5g51Ki0PMeta } from "/app/apps/concierge/pages/too-soon.vue?macro=true";
import { default as _91token_93K9Eccz0Z7LMeta } from "/app/apps/concierge/pages/view/[token].vue?macro=true";
export default [
  {
    name: "claim-received.spec___en",
    path: "/claim-received.spec",
    component: () => import("/app/apps/concierge/pages/claim-received.spec.js").then(m => m.default || m)
  },
  {
    name: "claim-received___en",
    path: "/claim-received",
    component: () => import("/app/apps/concierge/pages/claim-received.vue").then(m => m.default || m)
  },
  {
    name: "index.spec___en",
    path: "/index.spec",
    component: () => import("/app/apps/concierge/pages/index.spec.js").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/apps/concierge/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "issues.spec___en",
    path: "/issues.spec",
    component: () => import("/app/apps/concierge/pages/issues.spec.js").then(m => m.default || m)
  },
  {
    name: issuesHKnBN9s0EZMeta?.name,
    path: "/issues",
    component: () => import("/app/apps/concierge/pages/issues.vue").then(m => m.default || m),
    children: [
  {
    name: "issues-index.spec___en",
    path: "index.spec",
    component: () => import("/app/apps/concierge/pages/issues/index.spec.js").then(m => m.default || m)
  },
  {
    name: "issues___en",
    path: "",
    component: () => import("/app/apps/concierge/pages/issues/index.vue").then(m => m.default || m)
  },
  {
    name: "issues-items.spec___en",
    path: "items.spec",
    component: () => import("/app/apps/concierge/pages/issues/items.spec.js").then(m => m.default || m)
  },
  {
    name: "issues-items___en",
    path: "items",
    component: () => import("/app/apps/concierge/pages/issues/items.vue").then(m => m.default || m)
  },
  {
    name: "issues-shipment.spec___en",
    path: "shipment.spec",
    component: () => import("/app/apps/concierge/pages/issues/shipment.spec.js").then(m => m.default || m)
  },
  {
    name: shipmentl8iy5MyHI5Meta?.name,
    path: "shipment",
    component: () => import("/app/apps/concierge/pages/issues/shipment.vue").then(m => m.default || m),
    children: [
  {
    name: "issues-shipment-details.spec___en",
    path: "details.spec",
    component: () => import("/app/apps/concierge/pages/issues/shipment/details.spec.js").then(m => m.default || m)
  },
  {
    name: "issues-shipment-details___en",
    path: "details",
    component: () => import("/app/apps/concierge/pages/issues/shipment/details.vue").then(m => m.default || m)
  },
  {
    name: "issues-shipment-index.spec___en",
    path: "index.spec",
    component: () => import("/app/apps/concierge/pages/issues/shipment/index.spec.js").then(m => m.default || m)
  },
  {
    name: "issues-shipment___en",
    path: "",
    component: () => import("/app/apps/concierge/pages/issues/shipment/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "multiple-shipments-index.spec___en",
    path: "/multiple-shipments/index.spec",
    component: () => import("/app/apps/concierge/pages/multiple-shipments/index.spec.js").then(m => m.default || m)
  },
  {
    name: "multiple-shipments___en",
    path: "/multiple-shipments",
    component: () => import("/app/apps/concierge/pages/multiple-shipments/index.vue").then(m => m.default || m)
  },
  {
    name: "not-shipped.spec___en",
    path: "/not-shipped.spec",
    component: () => import("/app/apps/concierge/pages/not-shipped.spec.js").then(m => m.default || m)
  },
  {
    name: "not-shipped___en",
    path: "/not-shipped",
    component: () => import("/app/apps/concierge/pages/not-shipped.vue").then(m => m.default || m)
  },
  {
    name: "print-token___en",
    path: "/print/:token()",
    component: () => import("/app/apps/concierge/pages/print/[token].vue").then(m => m.default || m)
  },
  {
    name: "resolution.spec___en",
    path: "/resolution.spec",
    component: () => import("/app/apps/concierge/pages/resolution.spec.js").then(m => m.default || m)
  },
  {
    name: resolutionz9WI0ynKA4Meta?.name,
    path: "/resolution",
    component: () => import("/app/apps/concierge/pages/resolution.vue").then(m => m.default || m),
    children: [
  {
    name: "resolution-index.spec___en",
    path: "index.spec",
    component: () => import("/app/apps/concierge/pages/resolution/index.spec.js").then(m => m.default || m)
  },
  {
    name: "resolution___en",
    path: "",
    component: () => import("/app/apps/concierge/pages/resolution/index.vue").then(m => m.default || m)
  },
  {
    name: "resolution-review.spec___en",
    path: "review.spec",
    component: () => import("/app/apps/concierge/pages/resolution/review.spec.js").then(m => m.default || m)
  },
  {
    name: "resolution-review___en",
    path: "review",
    component: () => import("/app/apps/concierge/pages/resolution/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "start.spec___en",
    path: "/start.spec",
    component: () => import("/app/apps/concierge/pages/start.spec.js").then(m => m.default || m)
  },
  {
    name: "start___en",
    path: "/start",
    component: () => import("/app/apps/concierge/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "too-soon.spec___en",
    path: "/too-soon.spec",
    component: () => import("/app/apps/concierge/pages/too-soon.spec.js").then(m => m.default || m)
  },
  {
    name: "too-soon___en",
    path: "/too-soon",
    component: () => import("/app/apps/concierge/pages/too-soon.vue").then(m => m.default || m)
  },
  {
    name: "view-token___en",
    path: "/view/:token()",
    component: () => import("/app/apps/concierge/pages/view/[token].vue").then(m => m.default || m)
  }
]