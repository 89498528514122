export const ITEM_ISSUES = {
  damaged: 'DAMAGED',
  defective: 'DEFECTIVE_ITEM',
  missing: 'MISSING_ITEM',
  wrong: 'WRONG_ITEM',
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const SHIPMENT_ISSUES = {
  deliveredButNotReceived: 'ORDER_DELIVERED_NOT_RECEIVED',
  returnedToSender: 'RETURNED_TO_SENDER',
  stolen: 'STOLEN',
  trackingNotMoving: 'TRACKING_NOT_MOVING',
};

export const DISPLAY_ITEM_ISSUES = {
  [ITEM_ISSUES.damaged]: 'Damaged',
  [ITEM_ISSUES.defective]: 'Defective',
  [ITEM_ISSUES.missing]: 'Missing',
  [ITEM_ISSUES.wrong]: 'Wrong item',
  [SHIPMENT_ISSUES.deliveredButNotReceived]: 'Order Delivered Not Received',
  [SHIPMENT_ISSUES.returnedToSender]: 'Returned to sender',
  [SHIPMENT_ISSUES.stolen]: 'Stolen',
  [SHIPMENT_ISSUES.trackingNotMoving]: 'Tracking not moving',
};

export const ORDER_ISSUES = {
  notReceived: 'notReceived',
  issuesWithItems: 'issuesWithItems',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const ITEM_ISSUES_REQUIRING_IMAGES = [ITEM_ISSUES.damaged, ITEM_ISSUES.defective, ITEM_ISSUES.wrong];

export const ITEM_ISSUE_OPTIONS = [
  {
    label: 'Damaged',
    value: ITEM_ISSUES.damaged,
  },
  {
    label: 'Defective',
    value: ITEM_ISSUES.defective,
  },
  {
    label: 'Missing',
    value: ITEM_ISSUES.missing,
  },
  {
    label: 'Wrong item',
    value: ITEM_ISSUES.wrong,
  },
];
