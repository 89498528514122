import { shallowMount } from '@vue/test-utils';

import Component from './DropArea.vue';

let wrapper;
let vm;

describe('DropArea', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component);

    vm = wrapper.vm;
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });

  describe('behavioral', () => {
    test('dragging', async () => {
      await wrapper.trigger('dragover');
      expect(vm.dragging).toBeTruthy();

      await wrapper.trigger('dragleave');
      expect(vm.dragging).toBeFalsy();

      await wrapper.trigger('dragenter');
      expect(vm.dragging).toBeFalsy();
    });
  });

  describe('computed', () => {
    test('iterableFiles', async () => {
      expect(vm.iterableFiles).toEqual([]);

      await wrapper.setProps({ modelValue: ['file-1'] });
      expect(vm.iterableFiles).toEqual(['file-1']);
    });

    test('length', async () => {
      expect(vm.length).toBe(0);

      await wrapper.setProps({ modelValue: ['file-1'] });
      expect(vm.length).toBe(1);
    });

    test('subText', async () => {
      expect(vm.subText).toBe('Click to upload or drag and drop');

      await wrapper.setProps({ modelValue: ['file-1'] });
      expect(vm.subText).toBeUndefined();

      await wrapper.setProps({ multiple: true });
      expect(vm.subText).toBe('Add more');
    });
  });

  describe('methods', () => {
    test('handleClick', () => {
      return new Promise(resolve => {
        const fileInput = wrapper.find('input[type="file"]');

        fileInput.wrapperElement.addEventListener('click', () => {
          resolve();
        });

        vm.handleClick();
      });
    });

    test('handleDrop', async () => {
      await wrapper.trigger('dragover');

      vm.handleDrop({ dataTransfer: { files: ['file-1'] } });
      expect(vm.dragging).toBeFalsy();
      expect(vm.modelFiles).toEqual(['file-1']);
    });

    describe('handleFileChange', () => {
      test('one', () => {
        const fileInput = wrapper.find('input[type="file"]');
        const fileMock = new File(['(file content)'], 'test-file.txt', {
          type: 'text/plain',
        });

        Object.defineProperty(fileInput.element, 'files', {
          value: [fileMock],
          writable: false,
        });

        vm.handleFileChange();
        expect(vm.modelFiles.length).toBe(1);
      });
      test('multiple', async () => {
        await wrapper.setProps({ modelValue: ['file-1'], multiple: true });

        const fileInput = wrapper.find('input[type="file"]');
        const fileMock = new File(['(file content)'], 'test-file.txt', {
          type: 'text/plain',
        });

        Object.defineProperty(fileInput.element, 'files', {
          value: [fileMock],
          writable: false,
        });

        vm.handleFileChange();
        expect(vm.modelFiles.length).toBe(2);
      });
    });
  });
});
