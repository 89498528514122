
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="27" height="3" viewBox="0 0 27 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 1.49997H12.3835H27" stroke="url(#paint0_linear_16292_203819)" stroke-width="1.5"/>
<defs>
<linearGradient id="paint0_linear_16292_203819" x1="1.125" y1="1.99997" x2="27" y2="1.99997" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'ClaimTodoSolidLine',
    inheritAttrs: true,
  }
  </script>
