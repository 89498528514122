import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(nuxtApp => {
  const { $config, vueApp } = nuxtApp;

  if (import.meta.dev) return;

  Sentry.init({
    app: vueApp,
    dsn: $config.public.sentry.dsn,
    environment: $config.public.sentry.environment,
    integrations: [], // TODO: performance, session replay, tracing
  });
});
