import { shallowMount } from '@vue/test-utils';

import Component from './BaseModal.vue';

let wrapper;
let vm;

describe('BaseModal', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: {
        open: true,
      },
    });

    vm = wrapper.vm;
  });

  describe('behavioral', () => {
    test('close', async () => {
      await wrapper.find('[data-hubble="icon"]').trigger('click');
      expect(wrapper.emitted('close')).toHaveLength(1);
    });
  });

  describe('methods', () => {
    test('handleClose', () => {
      vm.handleClose();
      expect(wrapper.emitted('close')).toHaveLength(1);
    });
  });
});
