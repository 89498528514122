import { shallowMount } from '@vue/test-utils';

import Component from './ButtonCard.vue';

let wrapper;

describe('ButtonCard', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component);
  });

  describe('behavioral', () => {
    test('selected', async () => {
      expect(wrapper.html()).toMatchSnapshot();

      await wrapper.setProps({ selected: true });
      expect(wrapper.html()).toMatchSnapshot();
    });
  });
});
