import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useApplicationStore = defineStore('app', () => {
  const ready = ref(false);

  function setReady() {
    ready.value = true;
  }

  return {
    ready,
    setReady,
  };
});
