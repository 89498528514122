import { shallowMount } from '@vue/test-utils';

import { ORDER_ITEM } from '~/support/fixtures';

import Component from './ItemIssueCard.vue';

let wrapper;

describe('ItemIssueCard', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: {
        item: {
          issue: {
            quantity: 1,
          },
          ...ORDER_ITEM,
        },
      },
    });
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
