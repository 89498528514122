import { formatDate } from '../helpers';

import { BaseModel } from './base.model';

export class OrderMessageModel extends BaseModel<any> {
  get key() {
    return this._data.id || this._data.createdAt;
  }

  get image() {
    return this._data.image;
  }

  get medias() {
    return this._data.medias;
  }

  get message() {
    return this._data.messageContent;
    // return removeHTMLTags(this._data.messageContent);
  }

  get createdAtTime() {
    return new Date(this._data.createdAt).getTime();
  }

  get date() {
    return formatDate(this._data.createdAt);
  }

  get senderType() {
    return this._data.senderType;
  }

  get type() {
    return 'message';
  }

  get username() {
    return this._data.senderName || 'Username';
  }

  get initials() {
    return this.username
      .split(' ')
      .map((n: string[]) => n[0]?.toUpperCase())
      .join('');
  }

  get htmlBody() {
    return this._data?.htmlBody;
  }
}
