export const CLAIM_MEDIA_TYPE = 'CLAIM';

export const CREDIT_BONUS_TYPES = {
  fixed: 'fixed',
  percentage: 'percentage',
};

export const ClaimState = {
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
};
