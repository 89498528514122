import { shallowMount } from '@vue/test-utils';

import Component from './ShipmentCard.vue';

let wrapper;
let vm;

describe('ShipmentCard', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: {
        shipment: {
          items: [
            {
              id: '123',
              item: {
                id: '123',
                name: 'item',
              },
              quantity: 1,
            },
          ],
        },
      },
    });

    vm = wrapper.vm;
  });

  describe('behavioral', () => {
    test('selected', async () => {
      expect(wrapper.html()).toMatchSnapshot();

      await wrapper.trigger('click');
      expect(wrapper.html()).toMatchSnapshot();
    });
  });

  describe('methods', () => {
    test('selected', async () => {
      vm.handleClick();
      expect(vm.selected).toBeTruthy();
    });
  });
});
