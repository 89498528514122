import { useWhiteLabelStore, useApplicationStore, useOrdersStore, useClaimStore } from '~/store';

export default defineNuxtRouteMiddleware(async to => {
  const store = useWhiteLabelStore();
  const app = useApplicationStore();
  const orderStore = useOrdersStore();
  const claimStore = useClaimStore();
  const { store: queryStore } = to.query;
  const { currentOrder } = orderStore;
  const { token } = to.params;
  const hostname = window.location.hostname === 'localhost' ? null : window.location.hostname;

  let storeId = queryStore || currentOrder?.order?.storeId;

  // Early return if branding is already set
  if (store.get('brand100')) return app.setReady();

  if (to.path.includes('view') && token && !storeId) {
    const claim = await claimStore.findClaimById(token);
    storeId = claim?.storeId;
  }

  await store.find(hostname, storeId || null);

  app.setReady();
});
