import { mount } from '@pulse/support/test/utils';

import OTAModal from './OtaModal.vue';

describe('OTA Modal', () => {
  let wrapper;

  beforeEach(() => {
    wrapper = mount(OTAModal, {
      propsData: {
        open: true,
      },
    });
  });

  it('should display OTA Modal', () => {
    expect(wrapper.html()).toContain('disclaimer');
  });
});
