import { shallowMount } from '@vue/test-utils';

import MediaUploader from '~/components/image-uploader/MediaUploader.vue';
import { ORDER_ITEM } from '~/support/fixtures';

import Component from './ItemIssueDetailModal.vue';
import Rendered from './subcomponents/Rendered.vue';

let wrapper;
let vm;

describe('ItemIssueDetailModal', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      global: {
        stubs: {
          ItemIssueDetailModalForm: true,
          ItemIssueDetailModalProgressBar: true,
          ItemIssueDetailModalSelectCount: true,
          ItemIssueDetailModalSelectGranularity: true,
          MediaUploader,
          Rendered,
          TransitionChild: {
            template: '<div><slot /></div>',
          },
          TransitionRoot: {
            template: '<div><slot /></div>',
          },
        },
      },
      props: {
        item: ORDER_ITEM,
        open: true,
      },
    });

    vm = wrapper.vm;
  });

  describe('behavioral', () => {
    test('close', async () => {
      await wrapper.find('[data-hubble="close-button"]').trigger('click');
      expect(wrapper.emitted('close')).toHaveLength(1);
    });
  });

  describe('computed', () => {
    test('expectedIssueCount', () => {
      expect(vm.expectedIssueCount).toBe(1);

      Object.assign(vm.state, { ...vm.state, allItemsHaveSameIssue: false, numberOfItemsWithIssues: 2 });
      expect(vm.expectedIssueCount).toBe(2);
    });

    test('shouldShowForm', async () => {
      expect(vm.shouldShowForm).toBeFalsy();

      await wrapper.setProps({ item: { ...ORDER_ITEM, quantity: 1 } });
      expect(vm.shouldShowForm).toBeTruthy();

      await wrapper.setProps({ item: ORDER_ITEM });
      Object.assign(vm.state, { ...vm.state, allItemsHaveSameIssue: true });
      expect(vm.shouldShowForm).toBeTruthy();

      Object.assign(vm.state, { ...vm.state, allItemsHaveSameIssue: false, numberOfItemsWithIssues: 1 });
      expect(vm.shouldShowForm).toBeTruthy();
    });

    test('shouldAskForGranularity', () => {
      expect(vm.shouldAskForGranularity).toBeTruthy();

      Object.assign(vm.state, { ...vm.state, allItemsHaveSameIssue: true });
      expect(vm.shouldAskForGranularity).toBeFalsy();
    });
  });

  describe('props', () => {
    test('open', async () => {
      expect(wrapper.html()).toMatchSnapshot();

      await wrapper.setProps({ open: false });
      expect(wrapper.html()).toMatchSnapshot();
    });
  });

  describe('methods', () => {
    test('handleAddIssueClick', () => {
      Object.assign(vm.state, { ...vm.state, allItemsHaveSameIssue: false, numberOfItemsWithIssues: 2 });

      vm.handleAddIssueClick('issue-1');
      expect(wrapper.emitted('close')).toBeFalsy();

      vm.handleAddIssueClick('issue-2');
      expect(wrapper.emitted('close')).toBeTruthy();
    });

    test('handleCountContinue', () => {
      vm.handleCountContinue(5);
      expect(vm.state.numberOfItemsWithIssues).toBe(5);
    });

    test('handleGranularityContinue', () => {
      vm.handleGranularityContinue(true);
      expect(vm.state.allItemsHaveSameIssue).toBeTruthy();
    });
  });

  describe('watchers', () => {
    test('open', async () => {
      Object.assign(vm.state, {
        allItemsHaveSameIssue: true,
        footerLoaded: true,
        issues: ['issue'],
        numberOfItemsWithIssues: 3,
      });

      await wrapper.setProps({ open: false });
      await wrapper.setProps({ open: true });
      expect(vm.state.issues).toEqual([]);
      expect(vm.state.footerLoaded).toBeFalsy();
      expect(vm.state.allItemsHaveSameIssue).toBeNull();
      expect(vm.state.numberOfItemsWithIssues).toBeNull();
    });
  });
});
