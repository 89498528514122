import { shallowMount } from '@vue/test-utils';

import Component from './AppIcon.vue';

let wrapper;

describe('AppIcon', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: {
        name: 'building',
      },
    });
  });

  test('render', () => {
    expect(wrapper.html()).toMatchSnapshot();
  });
});
