import { shallowMount } from '@vue/test-utils';

import Component from './MediaUploader.vue';

let wrapper;
let vm;

describe('MediaUploader', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: { label: 'Images' },
    });

    vm = wrapper.vm;
  });

  describe('props', () => {
    test('required', async () => {
      expect(wrapper.html()).toMatchSnapshot;

      await wrapper.setProps({ required: true });
      expect(wrapper.html()).toMatchSnapshot;
    });
  });

  describe('computed', () => {
    describe('displayImages', () => {
      test('empty', () => {
        expect(vm.displayImages).toEqual([]);
      });

      test('string', async () => {
        wrapper = shallowMount(Component, { props: { images: ['file-1'] } });
        vm = wrapper.vm;

        expect(vm.displayImages).toHaveLength(1);
      });

      test('files', async () => {
        const fileMock = new File(['(file content)'], 'test-file.txt', {
          type: 'text/plain',
        });

        wrapper = shallowMount(Component, { props: { images: [fileMock] } });
        vm = wrapper.vm;

        expect(vm.displayImages).toHaveLength(1);
      });
    });
  });

  describe('methods', () => {
    test('openLightbox', () => {
      vm.openLightbox(1);

      expect(vm.lightboxIndex).toBe(1);
      expect(vm.showLightbox).toBeTruthy();
    });

    test('removePhoto', () => {
      wrapper = shallowMount(Component, { props: { images: ['file-1'] } });
      vm = wrapper.vm;

      vm.removePhoto(0);
      expect(vm.files).toHaveLength(0);
    });
  });
});
