import { shallowMount } from '@vue/test-utils';

import Component from './Badge.vue';

let wrapper;

describe('Badge', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component);
  });

  test('render', () => {
    expect(wrapper.html()).toMatchSnapshot();
  });
});
