import { shallowMount } from '@vue/test-utils';
import { vi } from 'vitest';

import MediaUploader from '~/components/image-uploader/MediaUploader.vue';
import { ITEM_ISSUES_REQUIRING_IMAGES } from '~/support/constants/issues';

import Component from './ItemIssueDetailModalForm.vue';
import ItemIssueDetailModalProgressBar from './ItemIssueDetailModalProgressBar.vue';

let wrapper;
let vm;

describe('ItemIssueDetailModalForm', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      global: {
        provide: {
          validations: {
            value: {},
          },
        },
        stubs: {
          ItemIssueDetailModalProgressBar,
          MediaUploader,
        },
      },
      props: {
        numberOfItemsWithIssues: 1,
      },
    });

    vm = wrapper.vm;
  });

  describe('computed', () => {
    test('shouldShowProgressBar', async () => {
      expect(vm.shouldShowProgressBar).toBeFalsy();

      await wrapper.setProps({ numberOfItemsWithIssues: 2 });
      expect(vm.shouldShowProgressBar).toBeTruthy();
    });

    test('shouldUploadImages', () => {
      expect(vm.shouldUploadImages).toBeFalsy();

      Object.assign(vm.form, { details: '', images: [], issueType: ITEM_ISSUES_REQUIRING_IMAGES[0] });
      expect(vm.shouldUploadImages).toBeTruthy();
    });
  });

  describe('methods', () => {
    describe('handleAddIssueClick', () => {
      test('invalid', () => {
        vm.$v.$touch = vi.fn();

        vm.handleAddIssueClick();
        expect(vm.$v.$touch).toHaveBeenCalled();
        expect(vm.$v.$invalid).toBeTruthy();
      });

      test('valid', async () => {
        Object.assign(vm.form, { details: 'details', images: ['image'], issueType: ITEM_ISSUES_REQUIRING_IMAGES[0] });

        await vm.handleAddIssueClick();
        expect(wrapper.emitted('add')).toHaveLength(1);
      });
    });
  });
});
