/* eslint-disable sort-keys-fix/sort-keys-fix */
export const ORDER_RESOLUTIONS = {
  refund: 'REFUND',
  reship: 'RESHIP',
  storeCredit: 'STORE_CREDIT',
};

export const DISPLAY_ORDER_RESOLUTIONS = {
  [ORDER_RESOLUTIONS.refund]: 'Refund',
  [ORDER_RESOLUTIONS.reship]: 'Reshipment',
  [ORDER_RESOLUTIONS.storeCredit]: 'Store Credit',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
