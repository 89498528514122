export default function useFileUploader() {
  const config = useRuntimeConfig();
  const signingServiceUrl = config.public.storage.mediaUploadUrl;
  const bucketName = config.public.storage.googleBucketName;

  async function getSignedUrl(media: any) {
    const response = await fetch(signingServiceUrl, {
      body: JSON.stringify({
        contentType: media.file.type,
        filename: media.file.name,
        mediaType: 'CLAIM',
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error('Failed to get signed url for image');
    }

    const responseData = await response.json();
    const uploadedFileName = responseData?.fileName;
    const signedUrl = responseData?.signedUrl;

    if (!signedUrl) {
      throw new Error('Signed URL not found in the response');
    }

    return { signedUrl, uploadedFileName, ...responseData };
  }

  async function uploadFile(media: any) {
    const signed = await getSignedUrl(media);
    const response = await fetch(signed.signedUrl, {
      body: media.file,
      headers: {
        'Content-Type': media.file.type,
      },
      method: 'PUT',
    });

    if (!response.ok) {
      throw new Error('Failed to upload image');
    }

    return {
      caption: `Uploaded at ${new Date().toLocaleString()}`,
      downloadLink: signed.downloadLink,
      fileKey: signed.publicUrl,
      fileType: media.file.type,
      filename: media.file.name,
    };
  }

  async function uploadFiles(medias: any[]) {
    return Promise.all(medias.map(media => uploadFile(media)));
  }

  return {
    uploadFiles,
  };
}
