import { shallowMount } from '@vue/test-utils';
import { setActivePinia, createPinia } from 'pinia';

import { ORDER_ITEM } from '~/support/fixtures';

import Component from './ItemCard.vue';

let wrapper;

describe('ItemCard', () => {
  beforeEach(() => {
    setActivePinia(createPinia());

    wrapper = shallowMount(Component, {
      props: {
        item: ORDER_ITEM,
      },
    });
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
