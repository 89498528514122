import { shallowMount } from '@vue/test-utils';

import Component from './IconCard.vue';

let wrapper;

describe('IconCard', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: {
        icon: 'building',
        title: 'Title',
      },
      slots: {
        default: 'Default slot content',
      },
    });
  });

  test('render', () => {
    expect(wrapper.html()).toMatchSnapshot();
  });
});
