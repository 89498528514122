function normalizeKeys(obj) {
  const keyMap = {
    backgroundColor: 'background-color',
    backgroundImage: 'background-image',
    brand100: 'primary-100',
    brand700: 'primary-700',
    brand800: 'primary-800',
    brand900: 'primary-900',
    desktopHeadlineFontSize: 'desktop-headline-font-size',
    desktopHeadlineLetterSpacing: 'desktop-headline-letter-spacing',
    desktopHeadlineLineHeight: 'desktop-headline-line-height',
    headlineTextTransform: 'headline-text-transform',
    logoWidthDesktop: 'logo-width-desktop',
    logoWidthMobile: 'logo-width-mobile',
    mobileHeadlineFontSize: 'mobile-headline-font-size',
    mobileHeadlineLetterSpacing: 'mobile-headline-letter-spacing',
    mobileHeadlineLineHeight: 'mobile-headline-line-height',
  };

  return Object.keys(obj).reduce((keys, key) => {
    if (!keyMap[key] && key !== 'buttonStyle') {
      return keys;
    }

    const normalizedKey = keyMap[key];

    if (key === 'buttonStyle') {
      const radius = {
        ROUNDED: '6px',
        ROUNDED_FULL: '9999px',
        SQUARE: '0px',
      };

      const style = obj[key];
      keys['button-style'] = radius[style];

      return keys;
    }

    if (/(url|image)/.test(normalizedKey)) {
      keys[normalizedKey] = `url(${obj[key]})`;

      return keys;
    }

    keys[normalizedKey] = obj[key];

    return keys;
  }, {});
}

export function applyTheme(theme) {
  const root = document.documentElement;
  Object.entries(normalizeKeys(theme)).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });
}

export function applyFontFace({ woff, woff2, family = 'OrderProtectionCustom' }) {
  const style = `
  @font-face {
    font-family: '${family}';
    src: url('${woff2}') format('woff2'),
         url('${woff}') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  `;

  const styleTag = document.createElement('style');
  styleTag.innerHTML = style;
  document.head.appendChild(styleTag);
  document.documentElement.style.setProperty('--headline-font-name', `'${family}'`);
}
