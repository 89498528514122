import { faker } from '@faker-js/faker';
import { mount } from '@vue/test-utils';
import { describe, expect, it } from 'vitest';

import CreateNewClaimHyperlink from './CreateNewClaimHyperlink.vue';

describe('CreateNewClaimHyperlink.vue', () => {
  it('should render the component correctly', () => {
    const wrapper = mount(CreateNewClaimHyperlink);

    expect(wrapper.text()).toContain('+ Create a new claim for this order');
  });

  it('should get properties correctly', () => {
    const testEmail = faker.internet.email();
    const testOrderNumber = faker.number.bigInt({ max: 9999999999, min: 1000000000 });
    const wrapper = mount(CreateNewClaimHyperlink, {
      props: {
        emailAddress: testEmail,
        orderNumber: String(testOrderNumber),
      },
    });

    const props = wrapper.props();

    expect(props.emailAddress).toBe(testEmail);
    expect(props.orderNumber).toBe(String(testOrderNumber));
  });
});
