import { shallowMount } from '@vue/test-utils';

import Component from './EditShippingAddressModal.vue';

let wrapper;

const address = {
  address1: '234 Main Street',
  city: 'Virginia Beach',
  country: 'United States',
  state: 'VA',
  zip: '23547',
};

describe('EditShippingAddressModal', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: { address },
    });
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
